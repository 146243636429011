<template>
  <div class="index_box">
    <!-- 顶部标题栏 -->
    <el-header class="header">
      <div class="head">
        <div class="title">大科智慧监测云平台</div>
        <div class="Htime">
          <div class="time">{{ hours }}</div>
          <div class="year">
            <div>{{ nowtime }}</div>
            <div>{{ week }}</div>
          </div>
        </div>
        <div class="exitpolice">
          <div class="police">
            <img src="../assets/images/message.png" alt="" />
          </div>
          <div class="exit" @click="exit">
            <img src="../assets/images/exit.png" alt="" />
          </div>
        </div>
      </div>
      <div class="gongBtn">
        <div @click="goHome">系统总览</div>
      </div>
    </el-header>

    <!-- 主体内容 -->
    <el-container class="main-container">
      <!-- 左侧导航栏 -->
      <el-aside class="aside">
        <el-menu
          unique-opened
          :default-active="activePath"
          class="el-menu-vertical-demo"
          background-color="#2C3B41"
          text-color="#fff"
          active-text-color="#68D8FE"
          router
          @open="handleOpen"
          @close="handleClose"
        >
          <div v-for="item in menulist" :key="item.id">
            <template
              v-if="
                (item.children && item.children.length == 0) || !item.children
              "
            >
              <el-menu-item
                :index="item.urlpath"
                @click="saveNavState(item.urlpath)"
              >
                <!-- <i :class="item.icon"></i> -->
                <span>{{ item.title }}</span>
              </el-menu-item>
            </template>
            <el-submenu v-else :index="item.id + ''">
              <template slot="title">
                <!-- <i :class="item.icon"></i> -->
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                v-for="subItem in item.children"
                :key="subItem.id"
                :index="subItem.urlpath"
                @click="saveNavState(subItem.urlpath)"
              >
                <template slot="title">
                  <!-- <i :class="subItem.icon"></i> -->
                  <span>{{ subItem.title }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </div>
        </el-menu>
      </el-aside>

      <!-- 右侧内容区域 -->
      <el-main class="main-content">
        <div class="mains">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nowtime: '',
      hours: '',
      week: '',
      // 左侧菜单数据
      menulist: [],
      // 被激活的链接地址
      activePath: '',
    }
  },
  mounted: function () {
    // _this = this;
    this.getMenuList()
    this.currentTime()
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    // 获取菜单
    async getMenuList() {
      const res = await this.$http.get('user/menu')
      if (res.code !== 1) return this.$message.error(res.meta.msg)
      this.menulist = res.data
      console.log(res, '1111')
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },

    // 时间获取
    currentTime() {
      setInterval(this.formatDate, 500)
    },
    formatDate() {
      const date = new Date()
      const year = date.getFullYear() // 年
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月
      const day = date.getDate() // 日
      const week = date.getDay() // 星期
      const weekArr = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let hour = date.getHours() // 时
      hour = hour < 10 ? '0' + hour : hour // 如果只有一位，则前面补零
      let minute = date.getMinutes() // 分
      minute = minute < 10 ? '0' + minute : minute // 如果只有一位，则前面补零
      let second = date.getSeconds() // 秒
      second = second < 10 ? '0' + second : second // 如果只有一位，则前面补零
      this.nowtime = `${year}-${month}-${day}`
      this.hours = `${hour}:${minute}:${second}`
      if (week == 0) {
        this.week = '星期日'
      } else if (week == 1) {
        this.week = '星期一'
      } else if (week == 2) {
        this.week = '星期二'
      } else if (week == 3) {
        this.week = '星期三'
      } else if (week == 4) {
        this.week = '星期四'
      } else if (week == 5) {
        this.week = '星期五'
      } else if (week == 6) {
        this.week = '星期六'
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    // 退出
    sign_out() {
      this.$message.success('退出成功')
      setTimeout(() => {
        this.$router.push('/')
      }, 700)
    },
    goHome() {
      this.$router.push('/home')
    },
    exit() {
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
}
.index_box {
  background-image: url(../assets/images/bg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh; // 使用视口高度
  overflow: hidden; // 防止页面滚动

  .el-container {
    height: 100%;
  }
  //  顶部
  .header {
    // width: 100%;
    // height: 92px !important;
    // padding: 0;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // position: relative;

    position: fixed; // 固定定位
    top: 0;
    left: 0;
    width: 100%;
    height: 92px !important;
    z-index: 1000; // 确保标题栏在最上层
    background-color: #0f1b31; // 根据需要设置背景色
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); // 添加阴影

    .head {
      // width: 100%;
      // height: 68px;
      // background: url('../assets/images/headBg.png') no-repeat;
      // background-size: cover;
      // background-position: center;
      // background-size: 100% 100%;
      // /* 行高是字体1.15倍 */
      // // line-height: 1.15;
      // display: flex;
      // justify-content: center;
      // /* flex-direction: column; */
      // align-items: center;
      // // margin-bottom: 7px;}

      width: 100%;
      height: 68px;
      background: url('../assets/images/headBg.png') no-repeat;
      background-size: cover;
      background-position: center;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      font-family: HarmonyOS Sans SC;
      font-weight: bold;
      font-size: 45px;
      color: #4886ff;
      background: linear-gradient(0deg, #3b7dff 0%, #b1ebff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 16px;
    }
    .Htime {
      position: absolute;
      // top: 20px;
      left: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .time {
      height: 24px;
      font-family: HarmonyOS Sans SC;
      font-weight: bold;
      font-size: 26px;
      color: #4486ff;
      // border-right: 1px solid #4486FF;
      padding-right: 20px;
      margin-right: 50px;
    }

    .year {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: HarmonyOS Sans SC;
      font-weight: bold;
      font-size: 16px;
      color: #4486ff;
    }
    .exitpolice {
      position: absolute;
      // top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80px;
    }
    .police {
      width: 26px;
      height: 28px;
    }
    .exit {
      width: 26px;
      height: 28px;
    }
    .gongBtn div {
      font-family: HarmonyOS Sans SC;
      font-weight: bold;
      font-size: 26px;
      color: #4486ff;
      text-align: center;
    }
    .gongBtn {
      position: absolute;
      // top: 22px;
      right: 200px;
      font-family: HarmonyOS Sans SC;
      font-weight: bold;
      font-size: 26px;
      color: #4486ff;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  }
  //  顶部右侧部分
  .head_right {
    color: #fff;
    display: flex;
    align-items: center;
    .edit_pass {
      margin: 0 15px;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        font-size: 18px;
        margin-right: 10px;
      }
    }
    .logout {
      margin-left: 15px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  // 左侧菜单
  .el-aside {
    width: 260px !important;
    height: 100%;
    background-color: #3a66b8;
    // 管理员信息
    .admin_box {
      width: 100%;
      height: 100px;
      border-bottom: 1px solid #ebebf0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 0 40px;
      .portrait {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background-color: #f0f0f0;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .admin_info {
        margin-left: 15px;
        color: #fff;
        display: flex;
        flex-direction: column;
        text-align: left;
        .a_name {
          font-size: 20px;
          margin-bottom: 5px;
        }
        .a_status {
          font-size: 20px;
        }
      }
    }

    .el-menu {
      border: 0;
      text-align: left;
    }
  }
  .el-main {
    padding: 0 20px 20px;
    box-sizing: border-box;
    // background-color: red;
    .mains {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-color: #3a66b8;
      padding: 20px;
    }
  }
}
// 修改点击选中后的样式
.el-submenu {
  border-left: 6px solid #3a66b8;
  box-sizing: border-box;
}
::v-deep .el-submenu__title {
  background-color: #3a66b8 !important;
  font-size: 16px;
  border-top: 2px solid #3a66b8;
}
::v-deep .is-opened .el-submenu__title {
  background-color: #3a66b8 !important;
}

::v-deep .is-opened.el-submenu .el-menu {
  background-color: #3a66b8 !important;
}
::v-deep .el-menu-item {
  background-color: #3a66b8 !important;
  font-size: 16px;
  box-sizing: border-box;
  border-left: 6px solid #3a66b8;
}
::v-deep .el-menu-item:hover {
  color: #4486ff !important;
  border-color: #4486ff;
}
::v-deep .el-submenu__title i,
.el-menu-item i {
  color: #ffffff;
}
::v-deep .is-active.el-menu-item i {
  color: #4486ff;
}
::v-deep .is-active.el-menu-item {
  color: #4486ff !important;
}
::v-deep .el-menu-item:hover i {
  color: #4486ff;
}
::v-deep .el-aside > .el-menu > .el-menu-item {
  border-top: 2px solid #213058;
}

// 主体内容
.main-container {
  margin-top: 92px; // 留出标题栏的高度
  height: calc(100vh - 92px); // 减去标题栏高度
  display: flex;

  // 左侧导航栏
  .aside {
    width: 260px !important;
  }

  // 右侧内容区域
  .main-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto; // 允许内容区域滚动
  }
}
</style>
